import { JsonValue } from 'shared-libs/helpers/types'
import {
  CacheItem,
  StoredEntry,
  SerializableKey,
  DEFAULT_CACHE_SIZE,
  SyncPersistentLRUCacheWithTTL,
} from 'shared-libs/models/cache'

/**
 *  Simple LRU cache with Time-To-Live that persists entries with localStorage.
 */
export class LRUCacheTTL<T extends JsonValue> extends SyncPersistentLRUCacheWithTTL<
  SerializableKey,
  T
> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag: string = 'LRU_TIMEOUT') {
    super(capacity, tag)
    window.addEventListener('storage', this.handleStorageChange)
  }

  public dispose(): void {
    window.removeEventListener('storage', this.handleStorageChange)
  }

  protected loadEntriesFromStorage(): StoredEntry[] {
    const entries: StoredEntry[] = []

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key && this.belongsToCache(key)) {
        const value = localStorage.getItem(key)
        entries.push([key, value])
      }
    }

    return entries
  }

  protected onSet(key: string, item: CacheItem<T>) {
    localStorage.setItem(key, JSON.stringify(item))
  }

  protected onDelete(key: string) {
    localStorage.removeItem(key)
  }

  protected onClear() {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i)
      if (key && this.belongsToCache(key)) {
        localStorage.removeItem(key)
      }
    }
  }

  private handleStorageChange = (event: StorageEvent) => {
    if (event.key && !this.belongsToCache(event.key)) {
      if (event.newValue === null) {
        // item was removed in another tab
        this.lruCache.delete(event.key)
      } else if (event.oldValue !== event.newValue) {
        // item was updated in another tab
        try {
          const item = JSON.parse(event.newValue) as CacheItem<T>
          this.lruCache.set(event.key, item)
        } catch (e) {
          console.error('Failed to parse updated cache item from another tab:', e)
        }
      }
    }
  }
}
