import _ from 'lodash'
import React from 'react'
import { WithMobileHeader } from '../contexts/mobile-header-context'
import { ErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/error-block'

interface IMobileError {
  title: string
  description: React.ReactNode
  errorMessage?: React.ReactNode
}

export const MobileError: React.FC<IMobileError> = (props) => {
  const { title, description, errorMessage } = props

  return (
    <WithMobileHeader title={title}>
      <div className="u-bumper--lg">
        {description && <p>{description}</p>}
        {errorMessage && <ErrorBlock errorText={errorMessage} />}
      </div>
    </WithMobileHeader>
  )
}
