import _ from 'lodash'
import React from 'react'
import queryString from 'query-string'
import apis from 'browser/app/models/apis'
import { MobileError } from './mobile-error'
import { Logger } from 'browser/apis/logging'
import { browserHistory } from 'browser/history'
import { Entity } from 'shared-libs/models/entity'
import { LRUCacheTTL } from 'browser/app/models/cache'
import { stringifyServerError } from 'shared-libs/models/utils'
import { TruckLoader } from '../components/truck-loader/truck-loader'
import {
  getEntityIds,
  normalizeUrlQuery,
  parseWorkflowTimeout,
  maybeResolveContainerId,
} from 'shared-libs/helpers/deeplink'
import { globalTranslationTable } from '../util/global-translation-table'
import { translateString } from 'shared-libs/helpers/utils'

interface DeepLinkProps {
  location?: Location
}

interface DeepLinkState {
  error?: any
}

export class DeepLink extends React.Component<DeepLinkProps, DeepLinkState> {
  /** Cache w/ TTL that wraps localStorage to map deeplinks to recent entity IDs */
  private entityCache?: LRUCacheTTL<string>

  constructor(props: DeepLinkProps) {
    super(props)

    this.state = {}

    this.entityCache = new LRUCacheTTL()
    this.entityCache.initialize()
  }

  public componentDidMount(): void {
    const url = window.location.href
    const search = queryString.parse(this.props.location.search)
    const props = normalizeUrlQuery(search)
    const entityIds = getEntityIds(props)

    this.handleDocumentAdd(entityIds, url, props)
  }

  public componentWillUnmount(): void {
    this.entityCache.dispose()
  }

  public render(): JSX.Element {
    const { error } = this.state

    const translatedTitle = translateString('Error', globalTranslationTable)
    const translatedDescription = translateString('Mobile_Deeplink_Error_Description', globalTranslationTable)

    if (error) {
      const errorMsg = error.message ? error.message : stringifyServerError(error, '\n')
      return <MobileError title={translatedTitle} description={translatedDescription} errorMessage={errorMsg} />
    }

    return <TruckLoader />
  }

  private handleDocumentAdd = async (entityIds: string[], url: string, props: any = {}) => {
    entityIds = await maybeResolveContainerId(entityIds, apis)

    // VD-10528 If `workflowTimeout` is set, see if we have an
    // execution entity matching the mixin ID that's been modified or created
    // within the specified time. We check from the cache only, since we specifically
    // want to check locally created records.
    const executionCreateTimeout = parseWorkflowTimeout(props)

    if (!_.isEmpty(entityIds) && executionCreateTimeout) {
      const entityId = this.entityCache.get(url)

      if (entityId) {
        Logger.logEvent('ResumeExistingWorkflow')
        const entity = await apis.getStore().getEntity(entityId)

        if (entity?.isStoryboardExecution) {
          return this.navigateToEntity(entity, props)
        }
      }
    }

    const entities = await apis.getStore().createEntities(entityIds, props)
    if (_.isEmpty(entities)) {
      Logger.logEvent('FailedToCreateEntity', { entityIds })
      this.setState({
        error: new Error('failed to create entity'),
      })
      return
    }

    const entity = entities[0]

    // for workflow, go directly to story
    if (_.size(entityIds) === 1 && entity.shouldAutoCreate && entity.isStoryboardExecution) {
      try {
        await entity.save()
        if (executionCreateTimeout) {
          this.entityCache.set(url, entity.uniqueId, executionCreateTimeout * 1000)
        }
      } catch (err) {
        console.error('Unable to create workflow', err)
        this.setState({
          error: err,
        })
        return
      }
    }

    this.navigateToEntity(entity, props)
  }

  private navigateToEntity(entity: Entity, props: any = {}) {
    const search = queryString.parse(this.props.location?.search)
    const { storyId } = props

    const searchParams = {
      ...search,
      jsonProps: undefined,
    }
    if (storyId) {
      searchParams.story_id = storyId
    }
    browserHistory.push({
      pathname: `/entity/${entity.uniqueId}`,
      search: queryString.stringify(searchParams),
    })
  }
}
